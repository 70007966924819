<template>
  <v-dialog v-model="mostrar" width="900" persistent>
    <v-card :loading="loading">
      <v-toolbar dense flat color="primary" dark>
        <v-toolbar-title>{{
          examen ? "Modificar examen" : "Crear examen"
        }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pt-3 pb-0 px-4">
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              v-model="nombre"
              label="Nombre"
              outlined
              dense
              :disabled="loading"
              :error-messages="errors.nombre"
              @input="$v.nombre.$touch()"
              @blur="$v.nombre.$touch()"
            ></v-text-field>
          </v-col>
          <v-col cols="12" v-if="examen">
            <span class="text-subtitle-1 text--secondary"
              >Descripción del examen:</span
            >
            <editor-texto
              ref="editorTexto"
              :texto="descripcion"
              contenido="examen"
              :idContenido="examen._id"
            ></editor-texto>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          small
          color="secondary"
          outlined
          :disabled="loading"
          @click="$emit('cancelar')"
          >Cancelar</v-btn
        >
        <v-btn
          small
          color="primary"
          :disabled="loading || !esValido"
          :loading="loading"
          @click="submit()"
          >Guardar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

import editorTexto from "@/components/globals/template/contenidos/editorTexto";

import { crearExamen, modificarExamen } from "./examenes.service";

export default {
  mixins: [validationMixin],

  components: {
    "editor-texto": editorTexto,
  },

  props: {
    mostrar: { type: Boolean, default: false },
    area: { type: String, required: false },
    examen: { type: Object, default: () => null },
  },

  computed: {
    ...mapGetters(["apps", "appName"]),
    errors() {
      const errors = {
        nombre: [],
        descripcion: [],
      };

      if (!this.$v.nombre.$dirty) return errors;
      !this.$v.nombre.required && errors.nombre.push("Campo requerido.");

      return errors;
    },

    esValido() {
      if (this.errors.nombre.length) return false;
      if (this.errors.descripcion.length) return false;
      return true;
    },
  },

  validations: {
    nombre: { required },
  },

  data: () => ({
    loading: false,
    nombre: "",
    descripcion: "",
  }),

  watch: {
    examen() {
      if (this.examen) this.setCurrentData();
    },
  },

  created() {
    if (this.examen) this.setCurrentData();
  },

  methods: {
    setCurrentData() {
      this.nombre = this.examen.nombre;
      this.descripcion = this.examen.descripcion;
    },

    async submit() {
      this.$v.nombre.$touch();
      if (!this.esValido) return;

      this.loading = true;
      try {
        const data = {
          nombre: this.nombre,
          ...(this.examen && {
            descripcion: this.$refs.editorTexto.obtenerTexto(),
          }),
          ...(!this.examen && { examenArea: this.area }),
        };

        const serverResponse = this.examen
          ? await modificarExamen(this.examen._id, data)
          : await crearExamen(data);
        this.loading = false;

        this.$validateResponse(serverResponse);
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          this.$emit("nuevoLog", serverResponse.nuevoLog);
          this.$emit("examenGuardado", serverResponse.examen);
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>
